// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getGeneralSettings = createAsyncThunk('', async (companyCode) => {
  try {
    // Retrieve the access token from localStorage
    const accessToken = JSON.parse(localStorage.getItem('userData'))?.accessToken

    // Ensure the access token exists before making the request
    if (!accessToken) {
      throw new Error('No access token found.')
    }
    const res = await fetch(process.env.REACT_APP_APOLLO_SERVER_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}` // Add access token in the Authorization header
      },
      body: JSON.stringify({
        query: `
        query Query($companyCode: String!) {
          isGeneralSetting(companyCode: $companyCode)
        }`,
        variables: {
          companyCode
        }
      })
    })

    const response = await res.json()
    return await response.data.isGeneralSetting
  } catch (error) {
    throw new Error(`Error fetching general settings: ${error.message}`)
  }
})

export const getGeneralSellerProfile = createAsyncThunk('', async (supplierCode) => {
  try {
    const res = await fetch(process.env.REACT_APP_APOLLO_SERVER_URI, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
        query Query($supplierCode: String!) {
          isGeneralSellerProfile(supplierCode: $supplierCode)
        }`,
        variables: {
          supplierCode
        }
      })
    })
    const response = await res.json()
    return await response.data.isGeneralSellerProfile
  } catch (error) {
    throw new Error(error)
  }
})

export const getLoggedInUserDetails = createAsyncThunk('/', async (email) => {
  try {
    const res = await fetch(process.env.REACT_APP_APOLLO_SERVER_URI, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
        query getCurrentUser($email: String!) {
          getCurrentUser(email: $email) {
            id
            isDeleted
            companyInfo {
              companyCode
            }
            supplierInfo {
              supplierCode
            }
            firstName
            lastName
            email
            designation
            avatar
            roles
            location
            mobileNo
            alternateMobileNo
            avatarUrl
            companyName
          }
        }`,
        variables: {
          email
        }
      })
    })
    const response = await res.json()
    return await response.data.getCurrentUser
  } catch (error) {
    throw new Error(error)
  }
})

export const downloadPurchaseOrder = createAsyncThunk('/download-po', async (payload) => {
  try {
    const res = await fetch(process.env.REACT_APP_APOLLO_SERVER_URI, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
        query DownloadPo($id: String!, $initiator: String!) {
          downloadPo(id: $id, initiator: $initiator) {
            file
            fileName
          }
        }`,
        variables: {
          id: payload.id,
          initiator: payload.initiator
        }
      })
    })
    const response = await res.json()
    const resp = await response.data.downloadPo
    const linkSource = `data:application/pdf;base64,${resp.file}`
    const downloadLink = document.createElement('a')
    const fileName = resp.fileName
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  } catch (error) {
    throw new Error(error)
  }
})

export const auctionsDataSlice = createSlice({
  name: 'auctionData',
  initialState: {
    isGeneralSetting: Boolean,
    currentUserData: {},
    getAuctionBranchName: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeneralSettings.fulfilled, (state, action) => {
      state.isGeneralSetting = action.payload
    })
    builder.addCase(getLoggedInUserDetails.fulfilled, (state, action) => {
      state.currentUserData = action.payload
    })
  }
})

export default auctionsDataSlice.reducer
